export default {
	inputCom: {
		send: "Send",
		after: "Resend after",
	},
	login_input: {
		title: "Login",
		email_label: "Email",
		email_tip: "Email address",
		password_label: "Password",
		password_tip: "Password",
		register_tip: "Don’t have accout?",
		register_tip2: " Sign up",
		forgot_tip: "Forgot Password",
		name_label: "Nickname",
		name_tip: "Nickname",
		code_label: "Code",
		code_tip: "Code",
		invationCode_label: "Invitation code",
		invationCode_tip: "Invitation code",
		newPassword_label: "New Password",
		newPassword_tip: "New Password",
		confirmPassword_label: "Confirm Password",
		goLogin: "Existing account, Log in",
		btn: "Login",
		btnLoad: "Login···",
		forget_btn: "Sign up",
		success_tips: "success",
	},
	register_input: {
		title: "Register",
		btn: "Register",
	},
	forgot_input: {
		title: "Retrieve password",
		return: "Return to login",
	},
	home: {
		title: "The world's first smart ring featuring an Al-powered sleep health algorithm \nand a Web3 incentive mechanism",
		title1: "AI + DePIN + RWA",
		title2: "We're creating a brand new [Web3] sleep ecosystem.",
	},
	card: {
		card1_title1: "Your AI Sleep Health Advisor",
		card1_title2: "",
		card1_title3: "",
		card1_text: `DeRing leverages advanced biometric technology to monitor various health metrics of users, providing personalized health advice through AI algorithms. The smart ring securely stores the collected personal health data through encryption, verifies it over the blockchain network, and offers token rewards to users who develop healthy habits.`,
		card2_title1: "Advanced Black Technology Smart Ring",
		card2_title2: "Earn more",
		card2_text: `DeRing is an astonishing black technology smart ring, crafted from titanium alloy, featuring excellent waterproof capabilities and robust health monitoring functions. It can track sleep, heart rate, blood oxygen, body temperature, and daily activities. Weighing about 3 grams, it is equipped with an advanced flexible battery, with a battery life of up to 7-10 days.`,
		card3_title1: "The Healthier The Richer",
		card3_title1_2: "The healthier you are",
		card3_title1_3: "The wealthier you become",
		card3_title2: "The world's first smart ring that introduces AI healthy sleep algorithm and Web3 incentives",
		card3_title3: "Join Beta",
		card3_btn: "See More",
		card3_btn2: "Pre-Order",
		card3_btn3: "Reserved",
		card3_letter1: "Congratulations",
		card3_letter2: "Dear No.$ reservation customer",
		card3_letter3:
			"Thank you for your support of DeRing! We will send you an email as soon as the sale starts. In the meantime, you are welcome to participate in the Genesis Whitelist event on the official website. If you have any questions, please feel free to contact us at $.",
		card3_text: `DeRing is the world’s first smart ring solution that integrates an AI health algorithm with a Web3 incentive mechanism. As a leading consumer-oriented DePIN application, we uphold the core philosophy of "The healthier, the richer." We motivate people to develop good sleep and health habits, allowing those who maintain healthy habits to continuously reap health benefits.`,
		card4_title: "TOKENS",
		card4_label: "address",
	},
	mint: {
		mint1_title1: "EASY TO",
		mint1_title2: "JOIN",
		mint1_text: `Users can easily register for DeRing using their email and purchase sleep rings on the official website to embark on a new sleep experience. During the testing phase, users can participate in whitelist activities for a chance to win prizes, gain early limited-edition purchase eligibility, and accumulate contributions. Users who contribute during the limited-time testing period will receive point rewards. (After the limited-time testing period ends, accumulated points will be distributed based on weighted accumulation. Hardware purchasers can then claim their points within the app.)`,
		mint2_title1: "SLEEP",
		mint2_title2: "GAME",
		mint2_text: `Users can access the sleep game anytime with their NFT and DeRing after acquiring them. Users will receive point rewards when they meet healthy sleep habit standards. Throughout the habit formation process, DeRing will continuously refine its sleep recommendations and scientific sleep guidelines based on users' sleep data to ensure they receive the best sleep plans promptly.`,
		mint3_title1: "HARDWARE",
		mint3_title2: "DATA",
		mint3_text: `When users wear the DeRing, they can accurately obtain and analyze various core health data. As users continue to use DeRing, they will accumulate more health data and gain a deeper understanding of their own sleep health. As a result, the health advice they receive will become more precise.`,
		mint4_title1: "REWARDING",
		mint4_title2: "HEALTHY SLEEPERS",
		mint4_text: `Ultimately, DeRing offers a reward system based on users' healthy sleep behaviors. In this system, the sleep score is not the sole determinant of point rewards; rather, sleep scores, user ecosystem engagement, and social behavior collectively determine the number of points a user can earn.`,
		mint5_title1: "HEALTHY",
		mint5_title2: "UNIVERSE",
		mint5_text: `In the DeRing world, we plan to create multiple series of figures and gameplay NFTs based on the Bored Ape Yacht Club and other renowned NFTs. These diverse NFTs will feature unique appearances and come with different rights, functionalities, and gameplay options to offer a varied gaming experience.`,
		mint6_text1: `Participate in DeRing with multiple beautiful NFTs`,
		mint6_text2: `We will periodically distribute NFTs and other random airdrops as rewards for those who actively contribute to healthy sleeping habits.`,
		mint6_text3: `If you want to enhance your sleep yield efficiency, you can also equip bonus NFTs to increase efficiency. `,
		mint6_text4: `All ring NFTs can consume tokens to continue upgrading, while promoting a healthy economic cycle in the DeRing ecosystem.`,
	},
	timeLine: {
		timeLine_title: "ROADMAP",
		timeLine_Q0: "2022 Q2: DeRing Smart Ring hardware project initiation",
		timeLine_Q0_text: "Officially started the design of the product appearance concept ID, product function definition, and hardware design.",
		timeLine_Q1: "2022 Q3: DeRing hardware chip selection and mold prototyping",
		timeLine_Q1_text: "Appearance mold prototyping test and hardware DEMO internal testing, function definition, and software development.",
		timeLine_Q2: "2023 Q2: Officially establish a project plan for integrating Web3 and smart hardware",
		timeLine_Q2_text:
			"Began proposing a new business model that integrates Web3 with smart rings, combining biometric data with AI, and defined the first concept of an AI personal assistant for smart rings.",
		// timeLine_Q4: '2024 Q3: User Airdrops, Official Launch of the Sleep Incentive Program, Public Sale of the Ring',
		timeLine_Q3: "2023 Q3-Q4: Secured seed funding, and the first batch of hardware was introduced",
		timeLine_Q3_text:
			"The first batch of DeRing smart rings was produced for internal testing, and the first phase of the DAO community workshop was initiated.",
		timeLine_Q4: "2024 Q1: Development and optimization of the sleep algorithm, APP started internal testing",
		timeLine_Q4_text:
			"The software sleep algorithm was refined in preparation for hardware iteration, and the APP function underwent internal testing, capable of performing multiple biometric monitoring.",
		timeLine_Q5: "2024 Q2: Community airdrop, public beta whitelist reservations, and DAO community launch",
		timeLine_Q5_text:
			"Participation in activities could earn a whitelist for purchasing the beta version of DeRing hardware, with rewards including NFTs and points airdrops, officially starting the recruitment of global community nodes.",
		timeLine_Q6: "2024 Q3: Co-branded NFT release, and the points incentive plan officially launched",
		timeLine_Q6_text:
			"The co-branded DeRing and limited edition NFTs  to be released, with the hardware rings and related NFT features going live, and the points incentive plan officially launched.",
	},
	help: {
		play_title: "How to Play",
		play_desc: "Coming soon...",
		save_title: "Sleep Data",
		save_desc: `Coming soon...`,
		economy_title: "Economic System",
		economy_desc: "Coming Soon...",
		attribute_title: "NFT Ecosystem",
		attribute_desc: "Coming Soon...",
	},
	menu: {
		home: "Home",
		about: "About",
		help: "Help",
		account: "My Account",
		NFT: "My NFT",
		wallet: "My Wallet",
		address: "My Address",
		Disconnect: "Disconnect",
		login: "Login",
		SLEEPER: "SLEEPER",
		MINT: "MINT",
		HELP: "HELP",
		whitepaper: "WHITEPAPER",
		SUBSCRIBE: "SUBSCRIBE",
	},
	subscribe: {
		title: "Get the freshest news from us",
		placeholder: "your mail address.",
		btn: "SUBSCRIBE",
	},
	elseTips: {
		service: "Term of service",
		privacy: "Privacy Policy",
		copyright: "©All right reserved",
	},
	wallet: {
		select_wallet: "Choose a wallet",
		down_load: "DownLoad",
		connect: "Connect",
		bind_success: "Binding successful",
		connect_success: "Successfully connected",
		connect_fail: "Connection failed, user refused to connect",
	},
	alert_tip: {
		copy_success: "Replicating Success",
		cancel_bind: "Unbind",
		cancel_bind_tip: "Are you sure to unbind the address?",
		cancel_bind_tip_title: "prompt",
		yes: "Yes",
		no: "No",
		network: "Network",
		cancel_success: "Successfully unbound",
		my_address: "My Address",
		obtain: "Obtain",
		login: "Please log in first",
		timer: "Request timed out, please try again",
		send_code: "The verification code has been sent, please check it in your email",
		success_email: "Please enter the correct email format",
		success_subscribe: "Subscribe successfully",
		success_register: "User registration successful",
		success_out: "Withdrawal successful",
		rechargeSuccess: "Recharge successfully, please refresh the page later to check the balance.",
		noDate: "NoDate",
		success_login: "login successfully",
		password_new: "The two passwords are inconsistent",
		success_change: "modify successfully,About to jump to the login page ~~~",
		linkWallet: "Please link wallet",
		out: "Disconnect or not?",
		warning: "Warning",
		depositNumber: "Please enter quantity",
		buySuccess: "Successful purchase",
	},
	confirmText: {
		mintBuyConfirm: {
			text: "Whether to buy?",
			no: "No",
			yes: "Yes",
		},
		mintConfirm: {
			text: "Purchase successful, buy again",
			no: "View",
			yes: "Again",
		},
		outConfirm: {
			text: "Transaction confirmed",
			yes: "Close",
		},
	},
	input_tip: {
		email: "Please enter your email address",
		subscribe: "subscribe",
	},
	about_index: {
		title: "DeRing NFT",
		desc: "DeRing has a variety of NFTs, which can be upgraded to a higher level and a higher rarity. ",
	},
	header_menu: {
		home: "Home",
		about: "About",
		help: "Help",
		whitepaper: "Whitepaper",
		account: "My Account",
		NFT: "My NFT",
		wallet: "My Wallet",
		address: "My Address",
		Disconnect: "Disconnect",
		login: "Login",
		download: "Download",
	},

	plan: {
		title: "Sleep data solutions",
		line1: "DeRing has reached a strategic partnership with Breo, as the head enterprise of health intellige",
		line2: "nt hardware, in the future Breo will provide us with scientific sleep data monitoring hardware, user",
		line3: "s can monitor personal sleep data through early bedtime behavior combined with hardware, sleep",
		line4: "data ownership is completely owned by users, through blockchain technology, we can ensure that",
		line5: "user data is stored safely, will not be tampered with, our users can also fully understand and contr",
		line6: `ol their sleep data, And through the consumption of health data to obtain the platform's tokens.We`,
		line7: "will also scientifically follow verified sleep data and analysis patterns through user-authorized pers",
		line8: "onal sleep data to help users better understand their sleep status and problems, and provide scien",
		line9: "tific suggestions for improvement.",
	},
	token: {
		address: "Contract address",
		card4_text: "TOKEN",
	},
	mentor: {
		title: "Consultant Introduction",
		Michael_Rosbash: "American geneticist and time biologist, recipient of the Nobel Prize in Physiology and Medicine",
	},
	partner: {
		title: "Partners",
	},
	user: {
		invited_num: "Number of friends successfully invited",
		invited_link: "Exclusive Link",
		invited_code: "Exclusive invitation code",
		record: "Reward Record",
		time: "Time",
		Invitee_account: "Invitee account",
		consumption_num: "Consumption quantity",
		reward: "Reward",
		hello: "HELLO",
		connectWallen: "CONNECT WALLEN",
		tab1: "Invitation record",
		tab2: "Reward record",
	},
	myWallet: {
		property_title: "Property",
		transaction_title: "Transaction records",
		token: "Token",
		amount: "Balance",
		option: "Operate",
		deposit_btn: "IN",
		draw_btn: "OUT",
		updateTime: "Time",
		category: "Type",
		draw: "OUT",
		num: "Number",
		status: "state",
		success: "success",
		lose: "fail",
		rarity: "rarity",
		name: "name",
		level: "level",
		money: "Money",
		bindTip: "The current user is not bound to a wallet, do you want to bind it?",
	},
	// 交易类型
	TransactionType: {
		type1: "In",
		type2: "Out",
		type3: "Update",
		type4: "",
		type5: "",
		type9: "blind box",
	},
	myNFT: {
		collected_nft: "Collected NFTs",
		transfer: "transfer",
		withdrawal: "withdrawal",
		scarce: "scarce",
		tab1: "Blind box",
		tab2: "NFT",
		myNft: "MY NFT",
		myBox: "MY Blind box",
	},
	transaction: {
		address_label: "Address",
		address_tips: "Please enter the address",
		num_label: "Number",
		num_tips: "Please enter the quantity",
		commission_label: "Commission",
	},
	ringPage: {
		page1: "The World's First WEB3 x Intelligent Hardware x NFT Project",
		page2:
			"DeRing will scientifically and accurately monitor your health data as well as your sleep, helping you to be more aware of your body data.",
		page22:
			"The industry's innovative solution for better health, better mood, more energy-it all starts withs leep. Wake up to an in-depth analysis of your deep sleep, light sleep, REM sleep, blood oxygen levels, and more, and reap the benefits of your own sleep data, as well as sleep optimisation solutions.",
		page3: {
			title: "Monitoring projects",
			content: {
				title1: "BloodOxygen",
				tip1: "SpO2 Anytime, Anywhere",
				title2: "Sleep",
				tip2: "Precise Sleep Monitoring",
				title3: "Heart Rate",
				tip3: "Track Heart Rate\nReduce Heart Risk",
				title4: "Stress",
				tip4: "Quantify Fatigue\nRest On Time",
				title5: "Steps",
				tip5: "Step Up\nTrack Daily Activity",
			},
		},
		page4: {
			swiper1_title: "Use DeRing to monitor every moment of your body's condition.",
			swiper1_text:
				"Whether you are on the run or stationary, DeRing will track your movement, steps, pulse, heart rate and recovery. And will analyse this data to find the ideal balance between activity and recovery for you to achieve your health goals.",
			swiper2_title:
				"Weekly, monthly, and yearly health data gives you a good idea of your sleep trends and gives you suggestions for improving sleep problems.",
			swiper2_text:
				"DeRing uses multiple biometric signals to monitor your health, and by analysing the data, it determines what problems you may be having and uses a smart little assistant on the app side to push you suggestions for improvement.",
			swiper3_title:
				"Using the DeRing Connection App, your health data will be converted into DeRing equity points REL or SLE through blockchain technology and smart algorithms.",
			swiper3_text:
				"DeRing will accelerate the efficiency of users in acquiring both types of points, allowing you to become healthier while acquiring more proof of equity value.",
		},
	},
	mobileMenu: {
		whitepaper: "WHITEPAPER",
		account: "ACCOUNT",
		NFT: "MY NFT",
		wallet: "WALLET",
		sleepr: "SLEEPER",
		mint: "MINT",
		help: "Q&A",
		timeLine: "ROADMAP",
	},
	mobileAccount: {
		link: "Invitation link",
		code: "Invitation code",
		success: "Successfully invited",
		record: "Reward record",
		record1: "Invitation record",
	},
	mobileMyNFT: {
		collected_nft: "Collected Number",
		Withdrawal: "Withdrawal",
		scarce: "稀缺",
	},
	mobileWallet: {
		asset: "ASSET",
		record: "TRADING RECORD",
		HandlingCharge: "Handling charge",
		submit: "Confirm out",
		code: "Code",
		address_label: "Address",
		num_label: "Num",
		nft: "Nft",
		type: "Types",
		store: "In",
		fetch: "Out",
	},
	mobileTips: {
		nullText: "No data available",
		connect: "Please operate on the computer",
		storeTips: "Please operate on the computer",
	},
	commomMint: {
		total: "Total supply",
		price: "Price ",
		text: " Users can set sleep goals by themselves. After setting sleep goals, they can enter the sleep game through....",
	},
	share: {
		title: "DeRing",
		description: `The world's first Web3 intelligent hardware xNFT project`,
	},
	header: {
		home: "Home",
		whitelist: "Mint NFT",
		roadmap: "Roadmap",
		qa: "Q&A",
		lan: "EN",
		download: "Download",
		qa: "Q&A"
	},
	download: {
		title: "Download Now",
	},
	dering: {
		banner_title: "Whitelist is online",
		banner_intro: "PARTICIPATE TO EARN BETA POINTS",
		banner_btn: "Register",
		page1_txt: "DeRing combines Depin, AI, and RWA, creating a new healthy lifestyle. ln DeRing world, The healthier, the richer.",
		page2_title: "Blood Oxygen",
		page2_intro: "Detect your blood oxygen whenever you want.",
		page2_circle_banner: "Blood Oxygen",
		page3_title: "Sleep Quality",
		page3_intro: "Detect your sleep quality accurately every night.",
		page3_circle_banner: "Sleep Quality",
		page4_title: "Step",
		page4_intro: "Record your daily movement by steps.",
		page4_circle_banner: "Step",
		page5_title: "Heart Rate",
		page5_intro: "Know your heart rate to provide heart disease.",
		page5_circle_banner: "Heart Rate",
		page6_title: "Stress",
		page6_intro: "Quantify your fatigue to take breaks in time.",
		page6_circle_banner: "Stress",
		page7_loaf1_title: "Long term use",
		page7_loaf1_txt: "Wireless charging, 7-10 days of batterylife, charging time less than an hour.",
		page7_loaf2_title: "Strong signal",
		page7_loaf2_txt: "Bluetooth connection distance exceeds 5 meters.",
		page7_loaf3_title: "Lightweight and portable",
		page7_loaf3_txt: "Made of skin friendly titanium alloymaterial, weighing about 3 grams.",
		page7_loaf4_title: "Patent algorithm",
		page7_loaf4_txt: "The core algorithm is a brand that has been researching patents for many years.",
		page7_loaf5_title: "Excellent waterproofing",
		page7_loaf5_txt: "5ATM level waterproofing \nperfectly covering daily use.",
		page7_loaf6_title: "Accurate monitoring",
		page7_loaf6_txt: "Nordic nRF52840 chip paired withclinical grade precision sensors.",
	},
	whitelist: {
		area1_main_title: "DeRing \nGlobal Beta Tester Recruitment",
		area1_sub_title: "Join the beta test and experience DeRing first",
		area1_btn: "Join now",
		area2_title: "Contribution quest",
		area2_intro:
			"     This internal testing event is a series of activities where users can complete contribution tasks to earn contribution points. Contribution points will be the key indicator for both the first phase lucky draw and the second phase whitelist draw. The first phase internal testing user recruitment will be launched on Twitter with an event draw. Users who complete all tasks will have a chance to participate. This draw will randomly select the first 100 internal testers, who will receive the first batch of internal testing DeRings and experience the internal testing version of the App. In the second phase whitelist draw, users can use their accumulated points to participate in the Genesis NFT whitelist draw. The more points they have, the more chances they will have to draw, and the higher their chances of getting a Genesis NFT whitelist. Users with whitelists can prioritize the purchase of Genesis NFTs and become the first official users of the DeRing universe.",
		area2_ps:
			"Notes: Genesis NFTs will be divided into four grades: common, rare, epic, and legendary. They will be sold in the form of blind boxes, with a limited quantity and on a first-come, first-served basis.",
		area2_task_title: "Task List",
		area2_task_get_coins: "Points earned",
		area2_task1_name: "1.Participate",
		area2_task1_intro: "Sign up and participate.",
		area2_task8_name: "2.Connecting Wallets",
		area2_task8_intro: "Connecting Wallets.",
		area2_task2_name: "3.Join DeRing Telegram ",
		area2_task2_intro: "Join in Telegram community.",
		area2_task3_name: "4.Follow {'@'}DeRing",
		area2_task3_intro: "Follow DeRing official X account.",
		area2_task4_name: "5.Retweet tweet",
		area2_task4_intro: "Retweet a specific tweet.",
		area2_task5_name: "6.Daily Twitter posts",
		area2_task5_intro: "Post a tweet with #Sleep and {'@'}DeRing.",
		area2_task6_name: "7.Inviting friends",
		area2_task6_intro: "Please check the detailed rules in the invitation record.",
		area2_btn1: "Complete",
		area2_btn2: "Invite",
		area2_change_btn1: "Invitation Record >",
		area2_change_btn2: "Back >",
		area2_invite_nodata: "No any invation record yet",
		area2_invite_th1: "Email",
		area2_invite_th2: "User registration time",
		area2_invite_th3: "Reward points amount",
		area2_invite_intro0: "Tips:",
		area2_invite_intro1:
			"When you invite friends, please ensure they correctly fill in your invitation code during registration. The number of reward points you receive from the 'invite a friend' task is directly related to the number of tasks your invited friends complete. The more tasks they complete, the more points you earn.",
		area2_invite_intro2:
			"When the friends you invite complete the four prerequisite tasks (register and log in, join Discord, follow Twitter, and forward designated tweets), you will receive 50/100/50/100 points for each task respectively. After completing these tasks, you will earn 100/50 points each time they invite a friend to sign up or post a daily post. If they do not complete these tasks, you will not receive points when they successfully invite friends to sign up or make daily posts.",
		area3_title: "Stage of the event",
		area3_card_t1: "Coming soon",
		area3_card1_t1: "6.25 \nOfficially launch",
		area3_card1_t2: "Beta testers recruitment",
		area3_card1_txt:
			"The DeRing official website is now officially launched. Users can participate in activities to earn points and have the opportunity to win a lottery for Beta testing eligibility.",
		area3_card2_t2: "Whitelist lottery",
		area3_card2_txt:
			"After the contribution tasks are closed, a whitelist lottery will be opened. The whitelist obtained through the lottery allows users to transfer.",
		area3_card3_t2: "Genesis NFT Sale",
		area3_card3_txt:
			"The official sale of the Genesis NFT is divided into a whitelist and public sale. Genesis NFT owners will receive hardware for free.",
		area3_card4_t2: "Public ring sale",
		area3_card4_txt: "All DeRings will have a unique identifier, used to bind with ecosystem-supported NFTs to earn points.",
		area4_title: "About DeRing",
		area4_intro1:
			"DeRing is the world's first smart ring solution that introduces an AI health algorithm combined with a Web3 incentive mechanism. It is a leading consumer-oriented DePIN application. The ring's hardware chip encrypts and stores human health data, and through various health data metrics, we identify the user's health condition. We tokenize user health habit data and secure it on the blockchain network, creating a human-centric incentive network.",
		area4_intro2:
			"Furthermore, DeRing is an astonishing piece of black technology in the form of a smart ring, crafted from titanium alloy with excellent waterproof capabilities and robust health monitoring features. Weighing about 3 grams and equipped with a flexible battery, it offers up to 10 days of battery life, achieving seamless and sustainable wear.",
		area4_intro3:
			"Adhering to the core philosophy of 'The healthier, the richer' through a unique computational mechanism of Web3 biometrics, DeRing motivates people to develop good sleep and health habits. With the 'Healthier, Wealthier' incentive strategy, those who maintain healthy habits continually earn health benefits.",
		area5_title: "Core Highlights",
		area5_banner1_title: "Promoting Global Sleep Health",
		area5_banner1_intro:
			"Sleep health issues are gaining global attention. DeRing is a global non-profit health organization focused on promoting sleep health through advanced technology based on the body's natural physiological rhythms.",
		area5_banner2_title: "Surpassing the traditional [Web3] industry",
		area5_banner2_intro:
			"We have launched the hardware foundation of the DeRing ecosystem, DeRing. DeRing integrates users' real physical health data with online digital incentives, surpassing the previous purely virtual [Web3] health application models.",
		area5_banner3_title: "Sleep better, earn more",
		area5_banner3_intro:
			"DeRing integrates elements of GameFi, SocialFi, and HealthFi, utilizing blockchain to link users' sleep data with digital assets. Ultimately, it will provide a reward system based on healthy sleep behaviors, rewarding those who maintain healthy sleep.",
		area5_banner4_title: "Personalized AI Health Assistant",
		area5_banner4_intro:
			"In addition to the smart ring, DeRing will leverage health medical knowledge and cutting-edge AI technology to create a personalized AI health assistant. This assistant will analyze users' health conditions daily, providing customized health services and recommendations.",
		area6_title: "Incentive Mechanism",
		area6_intro1:
			"DeRing combines AI, smart rings, NFTs, and integrated hardware and software to create a sustainable incentive mechanism that merges healthy living, gamified experiences, and value rewards. ",
		area6_intro2:
			"DeRing’s [Web3] biometric value reward algorithm focuses on 'user health behaviors,' 'item grade attributes,' and 'behavioral habit computing power' as core variables. ",
		area6_intro3:
			"Wearing DeRing every night provides exclusive health data and a sleep score report, fostering healthy habits through tracking one's own health status and realizing the principle of 'the healthier, the wealthier.",
		task_tips_step1_txt: "Open Telegram and join the group.",
		task_tips_step2_txt: "Copy the exclusive user code and send it to the group.",
		task_tips_step2_txt2: "USER CODE:",
		task_tips_btn_txt: "Open Telegram",
		save_txt: "Click here to save the picture",
		save_txt_mb: "Long press the picture to save",
	},
	cookie: {
		content:
			"We use cookies and similar technologies to help provide the content on the DeRing site and analytics purposes.You can learn more about cookies and how we use them in our",
		accept_btn: "Accept",
		decline_btn: "Decline",
		provicy: "Privacy Policy",
	},
	newHome: {
		background: `Over 60% of adults worldwide suffer from poor sleep, and sleep health has become a new health crisis for all mankind. Human health monitoring devices are becoming increasingly lightweight and technologically advanced. DeRing's mission is to lead a new revolution in wearable technology, building a global DePIN health data node network, training personalized AI health big models, and providing solutions to the global health crisis, using the core concept of "the healthier you are, the richer you are" and applying innovative AI health algorithms and Web3 incentive mechanisms.`,
		title1: "DeRing One",
		introduce1: "Your personalized smart ring with AI integration, your exclusive health mining machine",
		title2: "Precise Detection",
		introduce2: "Equipped with Nordic nRF52840 chip and clinical-grade precision sensors",
		title3: "Accumulate and Preserve Health Data",
		introduce3: "Pay attention to every subtle trace, and see the changes in your health day by day",
		feature: {
			title1: "Blood Oxygen",
			tip1: "Check your blood oxygen anytime, anywhere",
			title2: "Sleep",
			tip2: "Accurately monitor your sleep quality every night",
			title3: "Heart Rate",
			tip3: "Understand your heart rate to prevent heart disease",
			title4: "Stress",
			tip4: "Quantify your fatigue so you can rest in time",
			title5: "Steps",
			tip5: "Start recording your daily exercise from steps",
		},
		title4: "Extremely Lightweight Only 3 grams",
		introduce4_1: "The titanium alloy ring is as light as a few feathers",

		title5: "Safe and Skin-friendly Children Can Use It",
		introduce5: "Titanium alloy material, matte surface treatment, skin-friendly, wear-resistant and safe",

		title6: "Ultra-long Battery Life",
		introduce6_1: "Wireless charging for 1 hour",
		introduce6_2: "Battery life up to 10 days",
		introduce6_3: "Traditional ring battery life is only 3-7 days",

		title7_1: "A Fresh Start",
		title7_2: "Powerful Signal",
		introduce7: "Bluetooth connection distance can exceed 5 meters",

		title8: "Excellent Waterproofing",
		introduce8_1: "5ATM waterproof swimming",
		introduce8_2: "Can be worn safely for swimming and bathing",

		title9_1: `The world's first user-centric Web3 health and sleep incentive platform`,
		title9_2: "Global Node Recruitment Open",
		introduce9_1: "There are already seven nodes in Hong Kong, Canada, New Zealand, Dubai, Germany, Thailand, and Qatar",
		introduce9_2: "Join now",

		title_10_1: "Rings for all",
		introduce_10_1: "Safety for all",
		title_10_2: "Maintain a work-life balance",
		title_10_3: "Live healthy with friends",
		title_10_4: "Witness your beautiful moments",

		HealthMining: "Health is Mining",
		HealthMiningTips:
			"DeRing encourages users to develop good sleep and health habits. Through the unique computing power mechanism of Web3 biometric technology, the better the user's health habits, the higher the sleep score, and the more health incentives they will receive.",
		HealthScore: "The higher the health score",
		HealthGood: "The more health incentives users have",
		HealthDay: "Really get the value of health from accumulation over time",
	},
	newPc: {
		linkAll: "The integration of AI, Web3, DePIN, blockchain, and smart hardware",
		createDePin: "Create a Global Health DePIN Ecosystem",
		joinBeta: "Mint",
		advocate: "The world's first Web3 platform advocating\nfor a DePIN health data network",
		hardware: "DePIN\nHardware",
		network: "Health\nNetWork",
		ecosystem: "DePIN\nHealth service ecosystem",
		ecosystem_tip:
			"DeRing will create a comprehensive health ecosystem based on DePIN hardware, data networks, and services. By leveraging biometrics to build a DID system, it aims to improve global health.",
		hardware_ecosystem: "DeRing Health Device Ecosystem",
		hardware_ecosystem_detail:
			"DeRing is creating a smart health hardware ecosystem that offers comprehensive health monitoring and management from head to toe, encompassing health monitoring, functional enhancement, and therapeutic applications.",
		healthRich: "The healthier The wealthier",
		feature: {
			rewards: "Web3 reward",
			privacy: "Privacy computing",
			hardware: "Health tracking device",
			blockchain: "Blockchain authorization",
			algorithm: "AI health algorithm",
		},
		trainModel:
			"With prior user consent, we will anonymize and upload user data to train our DeRingAI health large language model. This model will be progressively integrated into our app and smart health devices.",
		service_ecosystem: "DeRing health service ecosystem",
		service_ecosystem_detail:
			"Leveraging DeRing DAO, DeRing will collaborate with leading brands in the global health industry to establish the DeRing health service ecosystem and expand the use cases of DeRing health points.",
		open: "Global node recruitment",
		lunch:
			"We have successfully launched a national node in Australia and signed agreements with 20 physical experience centers. A German national node has also been signed, with plans to expand to 40 countries and cities across Europe.",
		timeline: "DeRing Development Plan",
		timelineMap: {
			time1: {
				time: "2022 Q2",
				title: "Nurturing ideas, exploring markets",
				tip: "A full-scale launch of our hardware rings, each uniquely identified.",
			},
			time2: {
				time: "2022 Q3",
				title: "Project initiation, DeRing planning\nwhitepaper drafting",
				tip: "Preparing for launch by designing hardware rings and drafting a whitepaper.",
			},
			time3: {
				time: "2023 Q3",
				title: "Developing a sleep algorithm and starting early-stage financing",
				tip: "We optimized our sleep algorithm based on data from 1000 user tests.\nOur sleep algorithm was refined through data collected from 1000 user trials.",
			},
			time4: {
				time: "2024 Q1-Q3",
				title: "Whitelist reservation and time-limited testing",
				tip: "Launching DeRing Smart Ring and HealthToken: We've completed the development and testing of our smart ring, and are excited to officially launch the DeRing smart ring and its accompanying mobile app. Simultaneously, we're introducing HealthToken and initiating testnet staking.",
			},
			time5: {
				time: "2024 Q4",
				title: "Officially released for public sale",
				tip: "New features, new users: We've added stress monitoring and calorie tracking to our smart ring and are ramping up our marketing efforts.\nEarly Market Launch and Community Building: To kick off the launch of our smart ring's new features, we're initiating marketing efforts to attract early adopters and foster a vibrant community.",
			},
			time6: {
				time: "2025 Q1",
				title: "Launching NFT functionality",
				tip: "Launching NFT Features: Introducing NFT synthesis, upgrade, and repair functionalities to enhance platform engagement and user retention.\nEnhancing AI Health Algorithms: Further optimizing AI health algorithms to improve data analysis accuracy and the precision of personalized health recommendations.",
			},
			time7: {
				time: "2025 Q2",
				title: "Data management on blockchain",
				tip: "Testnet tokens are now officially migrated to the mainnet: Token system established.\nImplementing blockchain-based health data management: Uploading all user health data to a blockchain to ensure data security, transparency, and immutability.\nInitiating Global Partnerships: Collaborating with world-leading healthcare institutions and service providers to expand the use cases of HealthToken.",
			},
			time8: {
				time: "2025 Q1",
				title: "Launching a health AI assistant subscription service",
				tip: "Launching a health AI assistant subscription service: Users can subscribe to the service using HealthToken and receive personalized health recommendations and services.\nOptimizing the token economy model: Based on market feedback, we will optimize the token economic model to ensure the stability and long-term value of HealthToken.",
			},

			over: "To be continue…",
		},
		insert_1_title: "DeRing Health Data Network",
		insert_1_title_desc: "Pioneering smart ring with AI sleep tech and Web3 incentives",
		viewMore: "View Details",
		playVideo: "Play Full Video",
	},

	product: {
		tips1: "The first smart ring powered by AI, DePIN and Web3 incentive mechanism",
		tips2: "Measure your ring size with a Ring sizing kit for the best wear experience.",
		chooseColor: "Choose your color",
		chooseSize: "Choose your size",
		doNotNowSize: "I don't know my size",
		customerDetails: "Fill in your customer details",
		fullName: "FULL NAME",
		countryOrRegion: "COUNTRY/REGION",
		ZipCode: "ZIP CODE",
		city: "CITY",
		address: "ADDRESS",
		phoneNum: "PHONE NUMBER",
		confirm: "Confirm",
	},
};
