<script setup>
import { reactive, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { showToast } from "vant";
import { userRegisterApi, forgetPasswordApi, validateCodeApi } from "@/api";
import { isEmailAvailable, myLocalStorage } from "@/utils/index";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);
const handleChangeInput = () => {
	emit("update:modelValue", "login");
};

const formData = reactive({
	email: "",
	password: "",
	invationCode: "",
	verCode: "",
	nickName: "",
});
let flareToken = null;

// 注册
const handleRegister = async () => {
	if (!isEmailAvailable(formData.email)) {
		showToast(`${t("alert_tip.success_email")}`);
		return;
	}
	// console.log("flareToken", flareToken);
	// if (!flareToken) {
	//   // 请先通过人机验证
	//   console.log('flareToken', flareToken)
	//   return
	// }
	const res = await userRegisterApi(formData);
	if (res.msg === "success") {
		showToast(`${t("alert_tip.success_register")}`);
		myLocalStorage.set("userInfo", "userInfo", res.data);
		window.location.reload();
		// emit('update:modelValue', 'login')
	}
};

const codeTime = ref(59);
const codeBtnDisable = ref(false);
let timer = null;

// 重置验证码
watch(codeTime, (val) => {
	if (val === 0) {
		codeBtnDisable.value = false;
		codeTime.value = 59;
		clearInterval(timer);
	}
});

// 验证码
const handleGetCode = () => {
	if (!isEmailAvailable(formData.email)) {
		showToast(t("alert_tip.success_email"));
		return;
	}
	if (!codeBtnDisable.value) {
		codeBtnDisable.value = true;
		timer = setInterval(() => {
			codeTime.value -= 1;
		}, 1000);
	}
	forgetPasswordApi({ email: formData.email }).then((res) => {
		if (res.data) {
			showToast(`${t("alert_tip.send_code")}`);
		}
	});
};

const route = useRoute();
onMounted(() => {
	if (route.query.invcode) {
		formData.invationCode = route.query.invcode;
	}

	// const turnstile = window?.turnstile;
	// if (turnstile) {
	// 	try {
	// 		turnstile.ready(function () {
	// 			turnstile.render("#cloud-flare-box", {
	// 				sitekey: "0x4AAAAAAAatqa2hXTk0p9tT",
	// 				size: "normal",
	// 				callback: function (token) {
	// 					console.log(`Challenge Success ${token}`);
	// 					// flareToken = token
	// 					validateCodeApi({
	// 						response: token,
	// 					}).then((res) => {
	// 						console.log(res);
	// 						flareToken = res.data.errorCodes === "[]";
	// 					});
	// 				},
	// 				beforeInteractiveCallback: (data) => {
	// 					console.log("before-interactive-callback", data);
	// 				},
	// 			});
	// 		});
	// 	} catch (error) {
	// 		console.error("cloud-flare", error);
	// 	}
	// }
});
</script>
<template>
	<!-- <div class="nickName">
        <input type="text" :placeholder="t('login_input.name_tip')"   v-model="formData.nickName">
    </div> -->
	<div class="email">
		<input type="text" :placeholder="t('login_input.email_tip')" v-model="formData.email" />
	</div>
	<div class="password">
		<input type="password" :placeholder="t('login_input.password_tip')" v-model="formData.password" />
	</div>
	<div class="code">
		<input type="text" :placeholder="t('login_input.code_tip')" v-model="formData.verCode" />
		<van-button class="code_btn" color="#0500F9" :disabled="codeBtnDisable" round @click="handleGetCode">{{
			!codeBtnDisable ? `${t("inputCom.send")}` : codeTime + `${t("inputCom.after")}`
		}}</van-button>
	</div>
	<div class="Invitation_code">
		<input type="text" :placeholder="t('login_input.invationCode_tip')" v-model="formData.invationCode" />
	</div>
	<div class="email">
		<div id="cloud-flare-box"></div>
	</div>
	<van-button class="login_btn" round color="#0500F9" @click="handleRegister">{{ t("register_input.btn") }}</van-button>
	<div class="left_arrow" @click="handleChangeInput">
		<img src="@/assets/svg/left_arrow.svg" alt="" />
	</div>
</template>

<style lang="scss" scoped>
.email,
.code,
.Invitation_code,
.password,
.nickName {
	margin-bottom: 18px;
	width: 100%;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	input {
		width: 260px;
		height: 100%;
		border-radius: 23px;
		border: 1px solid #0400f9;
		outline: none;
		padding: 0 20px;
		background-color: transparent;
		font-size: 15px;
		color: #fff;
	}

	input::-ms-input-placeholder {
		text-align: center;
		font-size: 11px;
	}
	input::-webkit-input-placeholder {
		text-align: center;
		font-size: 11px;
	}
	input:focus {
		box-shadow: 0px 0px 15px 3px rgba(26, 9, 237, 0.74);
		caret-color: #0400f9;
	}
}
.code {
	display: flex;
	align-items: center;
	justify-content: center;
	input {
		width: 50%;
	}
	.code_btn {
		margin-left: 5px;
		width: 50%;
		height: 40px;
		font-size: 11px;
		color: #fff;
		font-family: "Gotham-Light";
	}
}
.login_btn {
	width: 260px;
	height: 46px;
	color: #fff;
	font-size: 21px;
	font-family: Gotham-Light;
	text-align: center;
	line-height: 46px;
	margin: 30px 0;
}
.left_arrow {
	width: 20px;
	cursor: pointer;
}
</style>
