import request from '@/utils/request'
 
// 定义并导出请求接口函数userLoginApi 参数值为loginform 参数类型为userType
export function testApi() {
    return request({ // 返回request请求
        url: '/telematics/v3/weather?location=北京&output=json&ak=ZI2kGvFdENKNYKTMEuZ1VBPs5c9nXl0O', // 请求接口的地址
        method: 'get', // 请求方式是post 还有get等方法(了解)
        data: '' // 请求的数据是loginform 在验证请求的时候可能请求的值为value
    })
}

export function validateCodeApi(data) {
    return  request({ 
        url: '/user/validateCode',
        method: 'POST', 
        data
    })
}

// 用户注册
export const userRegisterApi = (data) =>{
    return request({ 
        url: '/user/register',
        method: 'POST', 
        data
    })
}
// 忘记密码（发送验证码）
export const forgetPasswordApi = (data) =>{
    return request({ 
        url: '/user/forget?email',
        method: 'POST', 
        data
    })
}
// 登录
export const loginApi = (data) =>{
    return request({ 
        url: '/user/login',
        method: 'POST', 
        data
    })
}

// 修改密码
export const resetpasswordApi = (data) =>{
    return request({ 
        url: '/user/resetpassword',
        method: 'POST', 
        data
    })
}

// 查询适配钱包
export const walletSupportApi = () =>{
    return request({ 
        url: '/wallet/walletSupport',
        method: 'GET', 
    })
}

//提现
export const NFTwithdrawal = (data) =>{
    return request({ 
        url: '/wallet/withdraw',
        method: 'POST', 
        data
    })
}

//绑定用户非中心化钱包
export const bindingWalletApi = (data) =>{
    return request({ 
        url: '/wallet/bindingWallet',
        method: 'POST', 
        data
    })
}


// 用户绑定钱包查询
export const userWalletsApi = (data) =>{
    return request({ 
        url: '/wallet/userWallets' ,
        method: 'POST', 
        data
    })
}
//取消钱包绑定
export const cancelWalletsApi = (data) =>{
    return request({ 
        url: '/wallet/unbinding',
        method: 'POST', 
        data
    })
}
/*************我的钱包**************/

// 资产查询
export const userAccountApi = (data) =>{
    return request({ 
        url: '/wallet/userAccount' ,
        method: 'POST', 
        data
    })
}

// 资产查询
export const userWithdrawsApi = (data) =>{
    return request({ 
        // url: '/wallet/userWithdraws?userId=' + userId,
        url: '/wallet/userWithdraws',
        method: 'POST', 
        data
    })
}
// 币交易记录
export const usertransactionApi = (data) =>{
    return request({ 
        // url: '/wallet/userWithdraws?userId=' + userId,
        url: '/wallet/usertransaction',
        method: 'POST', 
        data
    })
}
//NFT提现
export const NFTwithdrawApi = (data) =>{
    return request({ 
        url: '/wallet/withdrawnft',
        method: 'POST', 
        data
    })
}
// NFT交易记录
export const NFTtransactionApi = (data) =>{
    return request({ 
        url: '/wallet/usertransactionNft',
        method: 'POST', 
        data
    })
}
// 转入地址获取
export const contractAddressApi = () =>{
    return request({ 
        url: '/wallet/contractAddress',
        method: 'GET', 
    })
}

// 提现
export const withdrawApi = (data) =>{
    return request({ 
        url: '/wallet/withdraw',
        method: 'POST', 
        data
    })
}
// 提现手续费
export const withdrawfeeApi = ({price}) =>{
    return request({ 
        url: '/wallet/withdrawfee?price=' + price,
        method: 'POST',
    })
}
// 获取角色NFT
export const getNFTRole = (data) =>{
    return request({ 
        url: '/role/queryuserroles',
        method: 'POST',
        data
    })
}
/*************个人中心 我的账户*****************/

// 用户奖励查询
export const inviterewardApi = (data) =>{
    return request({ 
        url: '/user/invitereward',
        method: 'POST', 
        data
    })
}
// 用户邀请人数列表查询
export const invationrecordApi = (data) =>{
    return request({ 
        url: '/user/invationrecord',
        method: 'POST', 
        data
    })
}
// 查询用户详情
export const getUserInfoApi = (data) =>{
    return request({ 
        url: '/user/userinfo',
        method: 'POST', 
        data
    })
}

// 邀请人数总数查询
export const invationcountApi = (data) =>{
    return request({ 
        url: '/user/invationcount',
        method: 'POST', 
        data
    })
}
/*************首页*****************/
// 订阅
export const userSubscribe = (data) =>{
    return request({ 
        url: '/user/usersubscribe',
        method: 'POST', 
        data
    })
}

/*************MY NFT*****************/

// 查询nft数量
export const getNftcountApi = (data) =>{
    return request({ 
        url: '/role/nftcount',
        method: 'POST', 
        data
    })
}
// 查询nft数量
export const getUserboxesApi = (data) =>{
    return request({ 
        url: '/boxes/userboxes',
        method: 'POST', 
        data
    })
}


/************* MINT *****************/
// 查看用户可购买盲盒数量列表
export const getBoxListApi = (data) =>{
    return request({ 
        url: '/boxes/boxesuserbuy',
        method: 'POST', 
        data
    })
}
// 购买盲盒，，拿到盲盒信息，然后调用合约
export const buyboxesApi = (data) =>{
    return request({ 
        url: '/boxes/userbuyboxes',
        method: 'POST', 
        data
    })
}

/************* 白名单任务 *****************/
// 查看白名单用户状态
export const getTaskDetail = () => {
    return request({ 
        url: '/task/listDetail',
        method: 'GET', 
    })
}


export const totalRecord = () => {
    return request({
        url: '/reservationRecord/myRecord'+ '?userId=-1',
        method: 'GET',
    })
}

export const myRecord = (userId) => {
    return request({
        url: '/reservationRecord/myRecord'+ '?userId=' + userId,
        method: 'GET'
    })
}

export const preOrder = (userId) => {
    return request({
        url: '/reservationRecord/save' + '?userId=' + userId,
        method: 'GET',
    })
}

export const getDownloadUrl = () => {
    return request({
        url: '/sys/getDownUrl',
        method: 'GET',
        
    })
}


export const InviteUrlBase = (data) => {
    return request({
        url: '/user/share' + data,
        method: 'GET',
        responseType: 'blob'
    }).then(response =>  URL.createObjectURL(new Blob([response])))
}

// 我的页面数据
export const getMyInfo = data => {
    return request({
      url: '/user/app/userBlance',
      method: 'POST',
      data,
    })
  }